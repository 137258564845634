<template>
  <moe-page title="后台账号管理">
    <!-- 筛选信息 -->
    <moe-inquire @search="accountSearch">
      <el-form-item label="账号">
        <el-input v-model.trim="accountParams.account" placeholder="输入账号" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="姓名">
        <el-input v-model.trim="accountParams.name" placeholder="输入姓名" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="角色">
        <el-select v-model="accountParams.roleId" placeholder="选择角色" filterable clearable>
          <el-option :label="item.name" :value="item.id" v-for="(item, index) of roleList" :key="index" />
        </el-select>
      </el-form-item>
    </moe-inquire>

    <!-- 账号信息 -->
    <moe-table ref="accountTable" url="/user/pageList" :params="accountParams">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$router.push('/account/add')">账号添加</el-button>
      </template>
      <el-table-column prop="account" label="账号" min-width="130" />
      <el-table-column prop="name" label="姓名" min-width="130" />
      <el-table-column prop="roleName" label="角色" min-width="130" />
      <el-table-column prop="state" label="状态" width="90">
        <template slot-scope="{ row }" v-if="user.id != row.id">
          <el-tag type="success" v-if="row.state == -1" @click="accountStatusChange(row.id, 0)" ><i class="el-icon-unlock" />启用</el-tag>
          <el-tag type="danger" v-else-if="row.state == 0" @click="accountStatusChange(row.id, -1)" ><i class="el-icon-lock" />禁用</el-tag>
          <el-tag type="info" v-else>无效</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="150">
        <template slot-scope="{ row }">{{ $moe_time.getTimeStamp(row.createTime) }}</template>
      </el-table-column>
      <el-table-column label="操作" width="300" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="warning" size="mini" icon="el-icon-edit" @click="$router.push(`/account/amend?id=${row.id}`)">修改</el-button>
          <template v-if="user.id != row.id">
            <el-button type="danger" size="mini" icon="el-icon-delete" @click="accountDelete(row.id)">删除</el-button>
            <el-button type="success" size="mini" icon="el-icon-refresh" @click="accountResetPwd(row.id)">重置密码</el-button>
          </template>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'AccountList',
  data() {
    //账号筛选信息
    let accountParams = {
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
    };

    return {
      accountParams, //账号筛选信息
      roleList: [], //角色列表
    };
  },
  created() {
    //获取所有角色信息
    this.getAllRoleList();
  },
  computed: mapState('user', ['user']),
  methods: {
    /**
     * 获取所有角色信息
     **/
    getAllRoleList() {
      // 调用所有角色API
      this.$moe_api.ROLE_API.roleListAll().then((data) => {
        if (data.code == 200) {
          this.roleList = data.result;
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },

    /**
     * 修改账号状态
     **/
    accountStatusChange(id, state) {
      // 调用修改账号状态API
      this.$moe_api.ACCOUNT_API.accountStatus({
        id,
        state,
      }).then((data) => {
        if (data.code == 200) {
          this.$moe_msg.success(state === 0 ? '启用成功' : '禁用成功');

          //刷新账号表格
          this.$refs.accountTable.loadingData();
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },

    /**
     * 账号删除
     **/
    accountDelete(id) {
      this.$moe_layer.confirm('是否要删除该账号信息 ?', () => {
        // 调用删除账号API
        this.$moe_api.ACCOUNT_API.accountDelete({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功');

            //刷新账号表格
            this.$refs.accountTable.loadingData();
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    },

    /**
     * 账号重置密码
     **/
    accountResetPwd(id) {
      this.$moe_layer.confirm('是否要重置该账号密码 ?', () => {
        // 调用删除账号API
        this.$moe_api.ACCOUNT_API.accountResetPwd({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('重置成功，密码为 123456');

            //刷新账号表格
            this.$refs.accountTable.loadingData();
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    },

    /**
     * 账号搜索
     **/
    accountSearch(isSearch) {
      if (!isSearch) {
        this.accountParams = {
          pageNum: 1, //当前页
          pageSize: 10, //每页条数
        };
      }

      //刷新账号表格
      this.$refs.accountTable.searchData();
    },
  },
};
</script>